import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eeinvoiceRouteNames } from '../enums/route-names';

export const EINVOICE_ROUTE_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

export function configureRoutes(routesService: RoutesService) {
  return () => {
    routesService.add([
      {
        path: '',
        name: 'einvoice::invoices',
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        order: 1,
      },
      {
        path: '/einvoice/invoice',
        name: 'einvoice::Issuedinvoices',
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        order: 1,
        parentName: 'einvoice::invoices',
      },
      {
        path: '/einvoice/invoice/submitted',
        name: 'einvoice::submittedinvoice',
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        order: 2,
        parentName: 'einvoice::invoices',
      },{
        path: '/einvoice/invoice/submitfaild',
        name: 'einvoice::submitfaildinvoice',
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        order: 3,
        parentName: 'einvoice::invoices',
      },
      {
        path: '/einvoice/invoice/signfaild',
        name: 'einvoice::signfaildinvoice',
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        order: 4,
        parentName: 'einvoice::invoices',
      },{
        path: '/einvoice/invoice/receivedinvoice',
        name: 'einvoice::receivedinvoices',
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        order: 4,
        parentName: 'einvoice::invoices',
      },,{
        path: '/einvoice/invoice/uploadedinvoice',
        name: 'einvoice::uploadedinvoices',
        iconClass: 'fas fa-book',
        layout: eLayoutType.application,
        order: 4,
        parentName: 'einvoice::invoices',
      },
      {
        path: '',
        name: 'einvoice::issuer',
        iconClass: 'fas fa-user',
        layout: eLayoutType.application,
        order: 3,
      },
      {
        path: '/einvoice/issuer',
        name: eeinvoiceRouteNames.issuer,
        iconClass: 'fas fa-user',
        layout: eLayoutType.application,
        order: 1,
        parentName: 'einvoice::issuer',

      },
   
      {
        path: '/einvoice/receiver',
        name: eeinvoiceRouteNames.receiver,
        iconClass: 'fas fa-user',
        layout: eLayoutType.application,
        order: 4,
      },
      {
        path: '/einvoice/invoiceItem',
        name: eeinvoiceRouteNames.invoiceItem,
        iconClass: 'fas fa-user',
        layout: eLayoutType.application,
        order: 4,
      },{
        path: '/einvoice/queue',
        name: eeinvoiceRouteNames.queue,
        iconClass: 'fas fa-user',
        layout: eLayoutType.application,
        order: 5,
      },

    ]);
  };
}
