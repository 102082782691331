import { Environment } from '@abp/ng.core';

const baseUrl = 'https://{0}.einvoice.sid-eg.com';

export const environment = {
  production: false,
  application: {
    baseUrl: baseUrl+'/',
    name: 'einvoice',
    logoUrl: 'https://sid-eg.com/wp-content/uploads/2020/03/output-onlinepngtools.png',
  },
  oAuthConfig: {
    issuer: 'https://{0}.login.einvoice.sid-eg.com',
    redirectUri: baseUrl,
    clientId: 'einvoice_App',
    skipIssuerCheck: true,
    //responseType: 'code',
    scope: 'offline_access einvoice role email openid profile',
    requireHttps: false,
  },
  apis: {
    default: {
      url: 'https://{0}.login.einvoice.sid-eg.com',
      rootNamespace: 'sid.platform.einvoice',
    },
    einvoice: {
      url: 'https://{0}.api.einvoice.sid-eg.com',
      rootNamespace: 'sid.platform.einvoice',
    },
  },
} as Environment;
